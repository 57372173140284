import React from 'react';

import Layout from '../components/layout';
import './404.sass';

const NotFoundPage = () => (
  <Layout pageType="blog">
    <div className="page-not-found">
      <section className="heading">
        <h1>Not Found</h1>
      </section>
      <section className="message">
        <div className="container">
          <div>Oh no! We can't find the page you're looking for.</div>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
